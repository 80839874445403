import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleLink", "form", "inputField"]

  showForm(event) {
    this.formTarget.style.display = "block";
    this.toggleLinkTarget.style.display = "none";
  }

  hideForm(event) {
    this.formTarget.reset();
    this.formTarget.style.display = "none";
    this.toggleLinkTarget.style.display = "block";
  }

  getValue() {
    if (this.hasInputFieldTarget) {
      return this.inputFieldTarget.value
    }
  }

  reset() {
    if (this.hasFormTarget) {
      this.formTarget.reset()
    }
  }
}
