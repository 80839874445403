import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["saveResolution"];
  static values = {
    structureId: Number,
    parentStructureId: Number,
  }

  resolutionTimer = null;

  connect() {
    $('.js-datepicker').datepicker({ dateFormat: 'yy-mm-dd' })
    $('.select2').each(function (i, obj) {
      if ($(obj).data('select2') == undefined) {
        $(obj).select2()
      }
    });
  }

  disconnect() {
    if (this.resolutionTimer) {
      clearTimeout(this.resolutionTimer);
      this.resolutionTimer = null;
    }
  }

  updateStructure(event) {
    event.preventDefault();
    const formData = new FormData(event.target.form);

    fetch(`/api/v4/structures/${this.structureIdValue}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: 'POST',
      body: new URLSearchParams(formData).toString()
    }).then(response => response.json())
      .then(data => {
        this.saveResolutionTarget.classList.remove("failure");
        this.saveResolutionTarget.innerHTML = "Changes have been saved.";
        this.updateStructureName(data.structure.display_name);
        this.displayResolutionForDuration();
      })
      .catch(error => {
        console.log("Error ", error)
        this.saveResolutionTarget.classList.add("failure");
        this.saveResolutionTarget.innerHTML = "There was an error updating the structure.";
        this.displayResolutionForDuration();
      });
  }

  // TODO: consider moving these side effects to an encapsulated hook on the StructureTree controller
  updateStructureName(name) {
    const structureNode = document.querySelector(`[structure-id="${this.structureIdValue}"]`);
    if (!structureNode) { return }

    structureNode.dataset.structureTreeStructureNameValue = name;

    const structureTitle = document.querySelector(`[structure-id="${this.structureIdValue}"] .structure-title`);
    if (!structureTitle) { return }

    structureTitle.innerHTML = name;

    const parentStructureNode = document.querySelector(`[structure-id="${this.parentStructureIdValue}"]`);
    if (!parentStructureNode) { return }

    const parentController = this.application.getControllerForElementAndIdentifier(parentStructureNode, 'structure-tree');
    if (!parentController) { return }

    parentController.sortChildren();
  }

  displayResolutionForDuration(duration = 5000) {
    this.saveResolutionTarget.classList.remove("hidden");

    if (this.resolutionTimer) {
      clearTimeout(this.resolutionTimer);
    }

    this.resolutionTimer = setTimeout(() => {
      this.saveResolutionTarget.classList.add("hidden");
    }, duration);
  }

}
